<template>
  <div>
    <div id="example1">
      <hot-table :settings="hotSettings"></hot-table>
    </div>
  </div>
</template>
<script>
import Handsontable from "handsontable";
import { HotTable } from "@handsontable/vue";
import "handsontable/dist/handsontable.full.css";

export default {
  data: function () {
    return {
      hotSettings: {
        startRows: 5,
        startCols: 10,
        nestedHeaders: [
          [
            "Mã Tree-Mã Supplier",
            "NỘI DUNG TÊN CÔNG VIỆC",
            "NHÃN HIỆU",
            "ĐƠN VỊ",
            "KHỐI LƯỢNG",
            "GIÁ NET",
            "TT NET",
          ],
        ],
             colWidths: 159,
          colWidths: [170, 300, 150, 150, 150, 150, 150, 150],
        // stretchH: "all",
        height: "auto",
   
        rowHeights: 40,
        manualColumnResize: true,
        columnHeaderHeight: 50,
        licenseKey: "non-commercial-and-evaluation",
        autoInsertRowboolean : true

        // stretchH: 'all',
      },
    };
  },
  components: {
    HotTable,
  }
};
</script>
<style>
.handsontable table thead th {
  color: green;
  background: rgb(204, 238, 204);
  font-weight: bold;
  font-size: 14px;
  vertical-align: middle;
}
.handsontable table thead th {
  /* line-height: 40px; */
  vertical-align: center !important;
}
.handsontable td {
vertical-align: middle !important;
}
.handsontableInput{

 line-height: 40px;
}
</style>